const host = process.env.REACT_APP_DEV_PORT
  ? `${location.hostname}:${process.env.REACT_APP_DEV_PORT}`
  : location.host;
export const ServerURL = `${location.protocol}//${host}`;
export const ServerAPIPrefix = ServerURL + '/api/v1/';
export const LabelPlatformServerPrefix = `${ServerAPIPrefix}labelplatform/`;
export const FileBrowserServerURL = ServerURL + '/filebrowser';
export const CVATServerURL = 'http%3A//cvat-service.cambrian-platform%3A8080';

export function setConfig(obj) {
  // here is the config from deployment
  window.AUTH_URL = obj.AUTH_URL;
  window.RES_URL = obj.RES_URL;
  window.FA_RES_URL = obj.FA_RES_URL;
  window.PROJECT_URL = RES_URL + '/project';
  window.SOLUTION_URL = RES_URL + '/solution';
  window.GRAFANA_DASHBOARD = obj.GRAFANA_DASHBOARD;
  window.ML_PIPELINE_CREATOR_MGR = obj.ML_PIPELINE_CREATOR_MGR;
  window.BILLING_DASHBOARD = obj.BILLING_DASHBOARD;
  window.SUBSCRIPTION_DASHBOARD = obj.SUBSCRIPTION_DASHBOARD;
  window.SOLUTION_DASHBOARD = obj.SOLUTION_DASHBOARD;
  window.DISABLE_OTA_AND_RELEASE = obj.DISABLE_OTA_AND_RELEASE;
  window.CURRENT_COMMIT_SHA = obj.CURRENT_COMMIT_SHA;
  window.CURRENT_COMMIT_TAG = obj.CURRENT_COMMIT_TAG;
  window.CURRENT_DEPLOY_DATE = obj.CURRENT_DEPLOY_DATE;
  window.MEC_VISION_URL = obj.MEC_VISION_URL;
  window.ENABLE_MEC_VISION = obj.ENABLE_MEC_VISION;
  window.LLM_EVALUATOR_MGR = obj.LLM_EVALUATOR_MGR;
  window.LIMIT_DEPLOY_COUNT = obj.LIMIT_DEPLOY_COUNT;
  window.ENABLE_LLM_CHAT = obj.ENABLE_LLM_CHAT;
  window.ENABLE_LLM_DOCUMENT_ASSISTANT = obj.ENABLE_LLM_DOCUMENT_ASSISTANT;
  window.ENABLE_PROJECT_RESOURCE = obj.ENABLE_PROJECT_RESOURCE;
  window.ENABLE_PLAYGROUND = obj.ENABLE_PLAYGROUND;
  window.SYSTEM_CONFIG =
    obj.SYSTEM_CONFIG && obj.SYSTEM_CONFIG !== ''
      ? JSON.parse(obj.SYSTEM_CONFIG)
      : {};
  window.ENABLE_CHAT = obj.ENABLE_CHAT;
  window.ENABLE_LLM_EVALUATOR = obj.ENABLE_LLM_EVALUATOR;
  window.ENABLE_LANGFLOW_UI = obj.ENABLE_LANGFLOW_UI;
  window.ENABLE_LANGFLOW = obj.ENABLE_LANGFLOW;
  window.INFERENCE_LIST = obj.INFERENCE_LIST;
  window.LOG_QUERY_MGR = obj.LOG_QUERY_MGR;
  window.HOMEPAGE = obj.HOMEPAGE || '/';
  window.AOI_MGR = obj.AOI_MGR || '';
  const MAX_PREDICT_IMAGE_COUNT = parseInt(obj.MAX_PREDICT_IMAGE_COUNT);
  window.MAX_PREDICT_IMAGE_COUNT = !isNaN(MAX_PREDICT_IMAGE_COUNT)
    ? MAX_PREDICT_IMAGE_COUNT
    : 100;
  window.SMTP_SERVER = obj.SMTP_SERVER;
  window.IPLAS_CONFIG =
    obj.IPLAS_CONFIG && obj.IPLAS_CONFIG !== ''
      ? JSON.parse(obj.IPLAS_CONFIG)
      : {};
  window.API_GATEWAY_CONFIG =
    obj.API_GATEWAY_CONFIG && obj.API_GATEWAY_CONFIG !== ''
      ? JSON.parse(obj.API_GATEWAY_CONFIG)
      : {};
  window.STREAM_MGR = obj.STREAM_MGR;
  window.AI_ASSISTANT_USAGE_GRAFANA = obj.AI_ASSISTANT_USAGE_GRAFANA;
  window.AI_ASSISTANT_CHANNEL = obj.AI_ASSISTANT_CHANNEL;
  window.IS_AZURE = obj.IS_AZURE;
  window.INSTANCE_INTERVAL = obj.INSTANCE_INTERVAL;
  window.ENABLE_AI_ASSISTANT_STANDALONE = obj.ENABLE_AI_ASSISTANT_STANDALONE;
  window.DEFAULT_PASSWORD = obj.DEFAULT_PASSWORD;
}

export function setPortalLocationConfig(obj) {
  // here is the config from database
  window.PORTAL_LOCATION = obj.Location;
  window.PORTAL_DEPLOYDOMAIN = obj.DeployDomain;
  window.PORTAL_DEFAULTSITE = obj.DefaultSite;
  window.PORTAL_SUPPORTSITES = obj.SupportSites;
  window.PORTAL_FUNCTIONS = obj.Functions;
  window.PORTAL_DEFAULTUSERDOMAIN = obj.DefaultUserDomain;
  window.PORTAL_USERDOMAIN = obj.UserDomain;
  window.PORTAL_ROLEPERMISSION = obj.RolePermission;
  window.PORTAL_AUTHSETTING = obj.AuthSetting;
  window.MAX_SOLUTION_SIZE = obj.MaxSolutionSize;
  window.MAX_VIDEO_SIZE = obj.MaxVideoSize;
  window.BU = obj.BU;
  window.ENABLE_AI_STATION = obj.ENABLE_AI_STATION;
  window.ENABLE_GROUP = obj.ENABLE_GROUP;
  window.ENABLE_DEPLOY = obj.ENABLE_DEPLOY;
  window.ENABLE_GRAFANA_DASHBOARD = obj.ENABLE_GRAFANA_DASHBOARD;
  window.ENABLE_RELEASE_WITHOUT_SERVING = obj.ENABLE_RELEASE_WITHOUT_SERVING;
  window.ENABLE_SOLUTION_MANAGEMENT = obj.ENABLE_SOLUTION_MANAGEMENT;
  window.ENABLE_PROJECT_QUOTA_API = obj.ENABLE_PROJECT_QUOTA_API;
  window.ENABLE_BILLING = obj.ENABLE_BILLING;
  window.MAX_IMPORT_DATASET_FILE_SIZE = obj.MAX_IMPORT_DATASET_FILE_SIZE
    ? obj.MAX_IMPORT_DATASET_FILE_SIZE
    : 209715200;
  window.MAX_SMT_REJUDGE_MONTH_QUERY = obj.MAX_SMT_REJUDGE_MONTH_QUERY || 1;
  window.RECALL_THRESHOLD = obj.RECALL_THRESHOLD;
  window.TNR_THRESHOLD = obj.TNR_THRESHOLD;
}

export const APIGatewayPrefix = ServerAPIPrefix + 'api_gateway';
